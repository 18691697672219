import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import TopNavBar from './components/TopNavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/About/Home';
import Layout from "./components/Layout";
import Generate from './components/Generate/Generate';
import Footer from "./components/Footer/Footer";
import Logout from "./components/Logout";
import GoogleLoginPage from "./components/UserAutentication/LogInPage";
import IsLoggedInUserCheck from "./components/UserAutentication/IsLoggedInUserCheck";
import { LoginProvider } from "./components/UserAutentication/LoginProvider";
import CheckoutPage from "./components/CheckoutPage/CheckoutPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ThankYouPage from "./components/CheckoutPage/ThankYouPage";
import MyGallery from "./components/MyGallery/MyGallery";
import PaywallPage from "./components/PaywallPage/PaywallPage";
import ServerDownPage from './components/ServerDownPage/ServerDownPage';
import GetApiStatus from './components/ServerDownPage/GetApiStatus';
import MyAccountPage from "./components/MyAccountPage/MyAccountPage";
import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import HelpCenterPage from "./components/About/DiscountBanner/HelpCenterPage/HelpCenterPage";
import ScrollToTop from './components/ScrollToTop';
import TermsAndConditionsPage from "./components/CheckoutPage/TermsAndConditionsPage/TermsAndConditionsPage";
import ViewTattooPage from "./components/ViewTattooPage/ViewTattooPage";
import PrivacyPolicyPage from "./components/CheckoutPage/PrivacyTermsPage/PrivacyPolicyPage";
import PricingPage from "./components/PaywallPage/PricingPage/PricingPage";
import AllowIPComponent from "./components/ServerDownPage/AllowIPComponent/AllowIPComponent";
import ApplyCoupon from "./components/Footer/ApplyCoupon/ApplyCoupon";
import HealthCheckPage from "./components/HealthCheck/HealthCheckPage";
import HowToCreateDesignUsingAi from "./components/LandingPages/HowToCreateDesignUsingAi";
import RecoveryPage from "./components/Recovery/RecoveryPage";
import WhyTattooGPTIsTheBest from "./components/LandingPages/WhyTattooGPTIsTheBest/WhyTattooGPTIsTheBest";
import OneTimePaywallPage from "./components/PaywallPage/OneTimePaywallPage";
import FeedbackPage from "./components/Feedback/FeedbackPage";

function App() {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const isApiDown = GetApiStatus();

    if (!clientId) {
        console.error('Google Client ID is not set');
        return <div>Google Client ID not set</div>;
    }

    if (isApiDown) {
        return <ServerDownPage />;
    }

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <HelmetProvider>
                <AllowIPComponent>
                    <LoginProvider>
                        <IsLoggedInUserCheck />
                        <TopNavBar />
                        <ScrollToTop />

                        {/* Define meta tags for the entire application */}
                        <Helmet>
                            <title>AI Tattoo Generator! Create Tattoo Designs from Text!</title>
                            <meta name="description" content="Turn Tattoo idea to cool design. generate AI Tattoo Now! Tattoos" />
                            <meta name="robots" content="index, follow" />
                            <link rel="canonical" href="https://www.tattoogpt.art" />
                        </Helmet>



                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route path="/" element={<Home />} />
                                <Route path="/welcome" element={<Home />} />
                                <Route path="/generate" element={<Generate />} />
                                <Route path="/signup" element={<Home />} />
                                <Route path="/myaccount" element={<MyAccountPage />} />
                                <Route path="/mygallery" element={<MyGallery />} />
                                <Route path="/logout" element={<Logout />} />
                                <Route path="/login" element={<GoogleLoginPage />} />
                                <Route path="/checkout" element={<CheckoutPage />} />
                                <Route path="/paywall" element={<OneTimePaywallPage />} />
                                <Route path="/thank-you" element={<ThankYouPage />} />
                                <Route path="/about-us" element={<AboutUsPage />} />
                                <Route path="/help-center" element={<HelpCenterPage />} />
                                <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                                <Route path="/pricing" element={<PricingPage />} />
                                <Route path="/viewTattoo/:userId/:imageId" element={<ViewTattooPage />} />
                                <Route path="/ApplyCoupon" element={<ApplyCoupon />} />
                                <Route path="/4444" element={<HealthCheckPage />} />
                                <Route path="/How-To-Create-Tattoo-Design-Using-Ai" element={<HowToCreateDesignUsingAi />} />
                                <Route path="/Feedback" element={<FeedbackPage />} />
                                <Route path="/The-Best-Tattoo-AI" element={<WhyTattooGPTIsTheBest />} />
                                <Route path="/The-Best-Tattoo-Generator" element={<WhyTattooGPTIsTheBest />} />
                                <Route path="/LastOfferForYou" element={<RecoveryPage />} />

                                {/* Handle 404 errors for non-existent routes */}
                                <Route path="*" element={<NotFoundPage />} />
                            </Route>
                        </Routes>

                        <Footer />
                    </LoginProvider>
                </AllowIPComponent>
            </HelmetProvider>
        </GoogleOAuthProvider>
    );
}

// 404 Page Component
function NotFoundPage() {
    return (
        <div style={{ textAlign: 'center', padding: '50px' , color:'white'}}>
            <Helmet>
                <title>404 - Page Not Found</title>
            </Helmet>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
}

export default App;
